import PropTypes from "prop-types"
import React from "react"

import scrollTo from "gatsby-plugin-smoothscroll"
import { ChevronDown } from "react-feather"
import "../css/glitch.css"

const Hero = ({ title, subtitle, description }) => (
  <div className="hero" id="hero">
    <div className="hero-content">
      <h1 className="glitch" data-text="Tyler Harnadek">
        Tyler Harnadek
      </h1>
      <h2>Software Engineer</h2>
      {/* <div className="links">
        <a className="btn" href="https://github.com/tharnadek">
          GitHub
        </a>

        <a className="btn" href="https://gitlab.com/tharnadek">
          GitLab
        </a>

        <a
          className="btn"
          href="https://www.linkedin.com/in/tyler-harnadek-93201b10b/"
        >
          LinkedIn
        </a>
      </div> */}
    </div>
    <button
      className="btn next"
      onClick={() => scrollTo("#about")}
      aria-label="scroll to about section"
    >
      <ChevronDown />
    </button>
  </div>
)

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
}

Hero.defaultProps = {
  title: ``,
  subtitle: ``,
  description: ``,
}

export default Hero
