import React from "react"

const ContactForm = () => (
  <form
    name="contact"
    method="POST"
    data-netlify="true"
    netlify-honeypot="bot-field"
  >
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
    <h1>Feel free to reach out.</h1>
    <label>
      Name
      <input type="text" name="name" placeholder="Your Name" />
    </label>
    <label>
      Email
      <input type="email" name="email" placeholder="you@email.com" />
    </label>
    <label>
      Message
      <textarea type="text" name="message" placeholder="Hi, let's chat..." />
    </label>
    <button className="btn flex" type="submit">
      Send
    </button>
  </form>
)

export default ContactForm
