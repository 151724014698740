import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import Hero from "../components/hero"
import ContactForm from "../components/contact"

import SEO from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const projects = data.projects.nodes
  return (
    <Layout>
      <SEO title="Home" keywords={[`tyler`, `harnadek`, `react`]} />
      <Hero />
      <div id="about" className="section">
        <MDXRenderer>{data.articles.nodes[0].body}</MDXRenderer>
      </div>
      {/* <div id="project" className="section">
        <h1>Projects</h1>
        {projects.map(project => (
          <>
            <h2>{project.frontmatter.title}</h2>
            <MDXRenderer>{project.body}</MDXRenderer>
          </>
        ))}
      </div> */}
      <div id="contact" className="section">
        <h1>Contact</h1>
        <ContactForm />
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    articles: allMdx(filter: { slug: { eq: "about" } }) {
      nodes {
        slug
        body
      }
    }
    projects: allMdx(
      filter: { slug: { regex: "/projects/" } }
      sort: { fields: [frontmatter___year], order: DESC }
    ) {
      nodes {
        slug
        body
        frontmatter {
          title
          year
        }
      }
    }
  }
`
export default IndexPage
